import { useCallback, useContext } from 'react';
import { themeContext } from './makeStyle';
import { makeTheme } from '../helpers/theme';
import Cookies from '@lobox/utils/utils/cookies';
import getCookieKey from '../../utils-pkg/utils/getCookieKey';

const themeObject = makeTheme(false, false);
type ThemeType = typeof themeObject;
type UseThemeType = {
  isRtl: boolean;
  isDark: boolean;
  changeTheme: Function;
  theme: ThemeType;
};

export const DARK_CLASS = 'dark-theme';

const useTheme = (): UseThemeType => {
  const ctx = useContext<UseThemeType>(themeContext as any);
  const { isRtl = false, theme = {}, isDark = false } = ctx || {};

  const changeTheme = useCallback(() => {
    document.body.classList.toggle(DARK_CLASS);
    const IS_DARK = getCookieKey('isDark');
    Cookies.set(IS_DARK, (!theme.isDark).toString());
  }, []);

  return { isRtl, isDark, changeTheme, theme };
};

export default useTheme;
