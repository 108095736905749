import { useState } from 'react';
import { useResizeWindow } from '@lobox/utils';
import {
  midDesktopBreakpoint,
  smallMobileBreakpoint,
  tabletBreakpoint,
} from '../helpers/theme';

const useCalcMediaSize = (): Partial<BreakPoints> => {
  const [state, setState] = useState<Partial<BreakPoints>>({});

  const calc = () => {
    const sizes = {
      isSmallMobile: false,
      isMoreThanSmallMobile: false,
      isTabletAndLess: false,
      isTablet: false,
      isMoreThanTablet: false,
      isMidDesktop: false,
      isDesktop: false,
    };
    const deviceWidth = window.innerWidth;

    if (deviceWidth <= smallMobileBreakpoint) {
      sizes.isSmallMobile = true;
    } else {
      sizes.isMoreThanSmallMobile = true;
    }

    if (deviceWidth < tabletBreakpoint) {
      sizes.isTabletAndLess = true;
    } else {
      sizes.isTabletAndLess = false;
    }
    if (deviceWidth > smallMobileBreakpoint && deviceWidth < tabletBreakpoint) {
      sizes.isTablet = true;
    }

    if (deviceWidth >= tabletBreakpoint) {
      sizes.isMoreThanTablet = true;
    }
    if (
      deviceWidth >= tabletBreakpoint &&
      deviceWidth <= midDesktopBreakpoint
    ) {
      sizes.isMidDesktop = true;
    }
    if (deviceWidth > midDesktopBreakpoint) {
      sizes.isDesktop = true;
    }
    setState(sizes);
  };

  useResizeWindow(calc, true);

  return { ...state };
};

export default useCalcMediaSize;
