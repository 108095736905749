'use client';
import React, { createContext, useEffect, useState } from 'react';
import { Cookies } from '@lobox/utils';
import { makeTheme } from '../helpers/theme';
import useCalcMediaSize from './useCalcMediaSize';
import getCookieKey from '../../utils-pkg/utils/getCookieKey';

interface SelectableAppreanceProps {
  appreance?: 'light' | 'dark';
  isDark?: never;
  isRtl?: boolean;
}

interface AutoAppreanceProps {
  appreance?: 'auto';
  isDark: boolean;
  isRtl?: boolean;
}

type ThemeProviderProps = { children: React.ReactNode } & (
  | SelectableAppreanceProps
  | AutoAppreanceProps
);

export const ThemeContext = createContext<
  | {
      isRtl: boolean | undefined;
      isDark: boolean | undefined;
      changeTheme: () => void;
      isSmallMobile: boolean | undefined;
      isMoreThanSmallMobile: boolean | undefined;
      isTabletAndLess: boolean | undefined;
      isTablet: boolean | undefined;
      isMoreThanTablet: boolean | undefined;
      isMidDesktop: boolean | undefined;
      isDesktop: boolean | undefined;
      theme: any;
    }
  | undefined
>(undefined);

export const ThemeProvider = ({
  appreance = 'auto',
  isDark: isDarkProp,
  isRtl = false,
  children,
}: ThemeProviderProps): JSX.Element => {
  const {
    isSmallMobile,
    isMoreThanSmallMobile,
    isTabletAndLess,
    isTablet,
    isMoreThanTablet,
    isMidDesktop,
    isDesktop,
  } = useCalcMediaSize();

  const [isDark, setIsDark] = useState(isDarkProp);

  const _isDark =
    appreance === 'auto'
      ? isDark
      : appreance === 'light'
        ? false
        : appreance === 'dark';
  const [theme, setTheme] = useState(() => ({
    isDark: _isDark,
    content: makeTheme(_isDark, isRtl),
  }));

  useEffect(() => {
    setTheme({ isDark: _isDark, content: makeTheme(_isDark, isRtl) });
  }, [_isDark]);

  const changeTheme = () => {
    if (appreance === 'auto') {
      const IS_DARK_KEY = getCookieKey('isDark');

      Cookies.set(IS_DARK_KEY, (!theme.isDark).toString());
      setTheme({
        isDark: !theme.isDark,
        content: makeTheme(!theme.isDark),
      });
    }
  };

  useEffect(() => {
    let observer = new MutationObserver(function (mutations) {
      mutations.forEach((mutationRecord) => {
        setIsDark(mutationRecord.target.classList.contains('dark-theme'));
      });
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
    });
    return () => observer.disconnect();
  }, [setIsDark]);

  return (
    <ThemeContext.Provider
      value={{
        isDark,
        isRtl,
        changeTheme,
        isSmallMobile,
        isMoreThanSmallMobile,
        isTabletAndLess,
        isTablet,
        isMoreThanTablet,
        isMidDesktop,
        isDesktop,
        theme: theme.content,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
