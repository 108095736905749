export const smallMobileBreakpoint = 320;
export const tabletBreakpoint = 1025; // it was 1124
export const midDesktopBreakpoint = 1125; // it was 1312
export const desktopBreakpoint = 1440;
export const safeAreaInsetBottom = 'var(--l-safe-area-bottom)';

export const palette = {
  autofillLight: '#EEF3FD',
  coal: '#072252',
  lead: '#4D719A',
  lead2: '#9DA2A4',
  trench: '#3F73E3',
  chatBlue: '#64A2FF',
  sky: '#83B4FF',
  sky_30: 'rgb(131, 180, 255, 0.3)',
  barBgLight: '#CBDEFC',
  brand: '#5384EE',
  darkBrand: '#314F8E',
  darkBrandHover: '#21345F',
  brand_4: 'rgba(83, 132, 238, 0.04)',
  brand_5: 'rgba(83, 132, 238, 0.05)',
  brand_10: 'rgba(83, 132, 238, 0.1)',
  brand_20: 'rgba(83, 132, 238, 0.2)',
  brand_30: 'rgba(83, 132, 238, 0.3)',
  brand_50: 'rgba(83, 132, 238, 0.5)',
  brand_70: 'rgba(83, 132, 238, 0.7)',
  white: '#FFFFFF',
  white_10: 'rgba(255, 255, 255, 0.1)',
  white_20: 'rgba(255, 255, 255, 0.2)',
  white_40: 'rgba(255, 255, 255, 0.4)',
  white_50: 'rgba(255, 255, 255, 0.5)',
  white_80: 'rgba(255, 255, 255, 0.8)',
  white_04: 'rgba(255, 255, 255, 0.04)',
  graphene: '#485363',
  graphene_60: 'rgba(72, 83, 99, 0.6)',
  highlightIcon: '#586880',
  gray: '#8493A8',
  gray_5: 'rgba(132, 147, 168, 0.05)',
  gray_10: 'rgba(132, 147, 168, 0.1)',
  gray_20: 'rgba(132, 147, 168, 0.2)',
  gray_30: 'rgba(132, 147, 168, 0.3)',
  gray_50: 'rgba(132, 147, 168, 0.5)',
  gray_60: 'rgba(132, 147, 168, 0.6)',
  gray_80: 'rgba(132, 147, 168, 0.8)',
  gray_70: '#E5E7EA',
  smoke: '#ECECEC',
  snow: '#FCFCFC',
  snow_90: 'rgba(252, 252, 252, 0.9)',
  blueFog: '#F6FCFF',
  arcticBlue: '#DCE9FC',
  sahra: '#E5D1A9',
  elephantTusk: '#FFF6E4',
  cloud: '#E1F5FE',
  chartPurple: '#CDD2FD',
  verifiedUser: '#00B0FF',
  success: '#43A047',
  success_10: 'rgba(67, 160, 71, 0.1)',
  success_4: 'rgba(67, 160, 71, 0.04)',
  success_5: 'rgba(67, 160, 71, 0.05)',
  warning: '#FF9A3E',
  irrelevant: '#F47B61',
  irrelevant_10: 'rgba(244, 123, 97, 0.1)',
  irrelevant_30: 'rgba(244, 123, 97, 0.3)',
  warning_5: 'rgba(255, 140, 0, 0.05)',
  warning_10: 'rgba(255, 154, 62, 0.1)',
  warning_30: 'rgba(255, 154, 62, 0.3)',
  error: '#D32F2F',
  error_4: 'rgba(211, 47, 47, 0.04)',
  error_5: 'rgba(211, 47, 47, 0.05)',
  error_10: 'rgba(211, 47, 47, 0.1)',
  error_50: 'rgba(211, 47, 47, 0.5)',
  mint: '#37C785',
  mint_10: 'rgba(55, 199, 133, 0.1)',
  mint_30: 'rgba(55, 199, 133, 0.3)',
  navyBlue: '#275395',
  navyBlue_10: 'rgba(39,83,149,0.1)',
  disabledError: '#D5898D',
  pendingOrange: '#FF8C00',
  pendingOrange_4: 'rgba(255, 140, 0, 0.04)',
  pendingOrange_10: 'rgba(255, 140, 0, 0.1)',
  pendingOrange_50: 'rgba(255, 140, 0, 0.5)',
  muteMidGray: '#606060',
  disabledGrayDark: '#848484',
  disabledGray: '#CACACA',
  darkSmoke: '#CDCDCD',
  techGray_20: 'rgba(132, 147, 168, 0.2)',
  techGray_10: 'rgba(132, 147, 168, 0.1)',
  hover_2: '#E6E9EE',
  hover: '#F1F4F9',
  hoverGray_15: 'rgba(132, 147, 168, 0.15)',
  navHover_10: 'rgba(132, 147, 168, 0.1)',
  hover_6: 'rgba(132, 147, 168, 0.6)',
  hover_50: 'rgba(241, 244, 249, 0.5)',
  iceBlue: '#EEF5FF',
  transparent: 'transparent',
  brown: '#8A6351',
  brown_4: 'rgba(138, 99, 81, 0.04)',
  // dark
  darkBackground: '#171717',
  darkHeaderBg: '#2F2F2F',
  headerBg: '#303030',
  popOverBg: '#242424',
  lightboxBackdrop: '#0F0F0F',
  popOverBg_50: 'rgb(36, 36, 36, 0.5)',
  popOverBg_60: 'rgba(36, 36, 36, 0.6)',
  hover_75: '#353535',
  gold: '#D9B97D',
  background2Light: '#F3F5F7',
  background2Light_60: 'rgba(243, 245, 247, 0.6)',
  background2Dark: '#181818',
  background3Dark: '#252525',
  darkHover: '#1B1B1B',
  darkHover_2: '#484848',
  black: '#000000',
  black_05: 'rgba(0, 0, 0, 0.05)',
  black_10: 'rgba(0, 0, 0, 0.1)',
  black_16: 'rgba(0, 0, 0, 0.16)',
  black_20: 'rgba(0, 0, 0, 0.2)',
  black_30: 'rgba(0, 0, 0, 0.3)',
  black_40: 'rgba(0, 0, 0, 0.4)',
  black_50: 'rgba(0, 0, 0, 0.5)',
  black_80: 'rgba(0, 0, 0, 0.8)',
  darkSecondary: '#393939',
  darkSecondary_10: 'rgb(57, 57, 57, 0.1)',
  darkSecondary_20: 'rgb(57, 57, 57, 0.2)',
  lightGreen: '#95C039',
  lightError: 'rgba(211, 47, 47, 0.05)',
  borderError: 'rgba(211, 47, 47, 0.3)',
  lightGray: '#F7F7F8',
  // gradiant
  darkModalHeaderBackground:
    'linear-gradient(180deg, #363636 0%, #202020 100%), #242424',
  lightModalHeaderBackground:
    'linear-gradient(191.69deg, #EEF5FF -0.77%, #FFFFFF 61.92%)',
  barGradiantLight: {
    start: '#83B4FF',
    end: '#5384EE',
  },
  barGradiantDark: {
    start: '#4A4A4A',
    end: '#313131',
  },
  tableBgDarkOdd: '#272727',
  tableBgDarkEven: '#292929',
  cornflowerBlue: '#1982C4',
  cornflowerBlueDark: '#14689C',
  heliotrope: '#9D57FC',
  heliotropeDark: '#7D45C9',
  darkTangerine: '#FFBD00',
  darkTangerineDark: '#CC9700',
  darkError: '#FF595E',
  darkErrorDark: '#CC474B',
  lightBrown: '#A86464',
  lightBrownDark: '#865050',
  blueGradientLayover:
    'linear-gradient(180deg, rgba(83, 132, 238, 0) 0%, #5384EE 100%)',
  blueGradientLayoverHover:
    'linear-gradient(180deg, rgba(83, 132, 238, 0.4) 0%, #5384EE 100%)',
  modalBlur: 'rgba(0, 0, 0, 0.50)',
};

export const makeTheme = (isDark = false, isRtl?: boolean) => ({
  isRtl,
  isDark,
  views: {
    smallMobileBreakpoint,
    tabletBreakpoint,
    midDesktopBreakpoint,
    desktopBreakpoint,
  },
  colors: {
    sky: palette.sky,
    coal: palette.coal,
    cloud: palette.cloud,
    black: palette.black,
    black_10: palette.black_10,
    black_16: palette.black_16,
    black_20: palette.black_20,
    black_30: palette.black_30,
    black_50: palette.black_50,
    black_40: palette.black_40,
    black_80: palette.black_80,
    brand: palette.brand,
    darkBrand: palette.darkBrand,
    darkBrandHover: palette.darkBrandHover,
    brand_10: palette.brand_10,
    brand_20: palette.brand_20,
    brand_30: palette.brand_30,
    brand_70: palette.brand_70,
    barBgLight: palette.barBgLight,
    blueFog: palette.blueFog,
    arcticBlue: palette.arcticBlue,
    darkSmoke: palette.darkSmoke,
    darkSecondary: palette.darkSecondary,
    popOverBg: palette.popOverBg,
    gray: palette.gray,
    gray_10: palette.gray_10,
    gray_30: palette.gray_30,
    gray_20: palette.gray_20,
    gray_50: palette.gray_50,
    gray_60: palette.gray_60,
    gray_70: palette.gray_70,
    hover: palette.hover,
    hover_2: palette.hover_2,
    hover_50: palette.hover_50,
    hoverGray_15: palette.hoverGray_15,
    iceBlue: palette.iceBlue,
    transparent: palette.transparent,
    error: palette.error,
    error_4: palette.error_4,
    error_5: palette.error_5,
    error_10: palette.error_10,
    error_50: palette.error_50,
    disabledError: palette.disabledError,
    pendingOrange: palette.pendingOrange,
    pendingOrange_4: palette.pendingOrange_4,
    pendingOrange_10: palette.pendingOrange_10,
    pendingOrange_50: palette.pendingOrange_50,
    muteMidGray: palette.muteMidGray,
    white: palette.white,
    white_10: palette.white_10,
    white_20: palette.white_20,
    white_40: palette.white_40,
    white_50: palette.white_50,
    white_80: palette.white_80,
    background2Light: palette.background2Light,
    mint: palette.mint,
    mint_10: palette.mint_10,
    mint_30: palette.mint_30,
    navyBlue: palette.navyBlue,
    navyBlue_10: palette.navyBlue_10,
    navHover_10: palette.navHover_10,
    graphene: palette.graphene,
    graphene_60: palette.graphene_60,
    techGray_20: palette.techGray_20,
    techGray_10: palette.techGray_10,
    hover_75: palette.hover_75,
    disabledGray: palette.disabledGray,
    disabledGrayDark: palette.disabledGrayDark,
    highlightIcon: palette.highlightIcon,
    warning: palette.warning,
    irrelevant: palette.irrelevant,
    irrelevant_10: palette.irrelevant_10,
    irrelevant_30: palette.irrelevant_30,
    success: palette.success,
    success_5: palette.success_5,
    success_4: palette.success_4,
    success_10: palette.success_10,
    snow: palette.snow,
    snow_90: palette.snow_90,
    trench: palette.trench,
    warning_5: palette.warning_5,
    warning_10: palette.warning_10,
    warning_30: palette.warning_30,
    brand_5: palette.brand_5,
    brand_4: palette.brand_4,
    background2Dark: palette.background2Dark,
    lightGray: palette.lightGray,
    brown: palette.brown,
    brown_4: palette.brown_4,
    lightboxBackdrop: palette.lightboxBackdrop,
    lightGreen: palette.lightGreen,
    lightError: palette.lightError,
    borderError: palette.borderError,
    darkHover: palette.darkHover,
    darkHover_2: palette.darkHover_2,
    lead: palette.lead,
    lightBrownDark: palette.lightBrownDark,
    cornflowerBlue: palette.cornflowerBlue,
    cornflowerBlueDark: palette.cornflowerBlueDark,
    heliotrope: palette.heliotrope,
    heliotropeDark: palette.heliotropeDark,
    darkTangerine: palette.darkTangerine,
    darkTangerineDark: palette.darkTangerineDark,
    darkError: palette.darkError,
    darkErrorDark: palette.darkErrorDark,
    // themed text
    background: isDark ? palette.popOverBg : palette.white,
    background2: isDark ? palette.background2Dark : palette.background2Light,
    background3: isDark ? palette.background3Dark : palette.white,
    background4: isDark ? palette.darkSecondary : palette.white,
    background6: isDark ? palette.darkSecondary : palette.hover_2,
    background7: isDark ? palette.disabledGray : palette.brand,
    background8: isDark ? palette.darkSecondary : palette.brand_10,
    background9: isDark ? palette.popOverBg : palette.snow,
    background10: isDark ? palette.hover_75 : palette.brand_10,
    background11: isDark ? palette.smoke : palette.darkBackground,
    backgroundIcon: isDark ? palette.hover_75 : palette.hover,
    backgroundIconSecondary: isDark ? palette.darkSecondary : palette.hover,
    colorIcon: isDark ? palette.disabledGray : palette.black,
    colorIconSecond: isDark ? palette.white : palette.graphene,
    colorIconThird: isDark ? palette.disabledGrayDark : palette.smoke,
    colorIconForth: isDark ? palette.muteMidGray : palette.graphene,
    colorIconForth2: isDark ? palette.muteMidGray : palette.graphene_60,
    colorIconFifth: isDark ? palette.muteMidGray : palette.brand,
    colorIconSixth: isDark ? palette.hover_75 : palette.brand_30,
    colorIconSeventh: isDark ? palette.disabledGray : palette.brand,
    colorIconEighth: isDark ? palette.disabledGrayDark : palette.techGray_20,
    colorIconNinth: isDark ? palette.disabledGrayDark : palette.brand,
    colorIconTen: isDark ? palette.disabledGray : palette.gray_50,
    border: isDark ? palette.disabledGrayDark : palette.gray,
    borderSecond: isDark ? palette.techGray_20 : palette.transparent,
    borderThird: isDark ? palette.techGray_20 : palette.brand_5,
    borderForth: isDark ? palette.darkSecondary_10 : palette.snow,
    borderFifth: isDark ? palette.muteMidGray : palette.smoke,
    borderSixth: isDark ? palette.darkSecondary : palette.techGray_20,
    borderSeventh: isDark ? palette.muteMidGray : palette.gray_50,
    borderEighth: isDark ? palette.muteMidGray : palette.techGray_20,
    muteMidGray_techGray_20: isDark ? palette.muteMidGray : palette.techGray_20,
    muteMidGray_coal: isDark ? palette.muteMidGray : palette.coal,
    smoke_coal: isDark ? palette.smoke : palette.coal,
    primaryText: isDark ? palette.disabledGray : palette.graphene,
    secondaryText: isDark ? palette.white : palette.coal,
    brand5_gray10: isDark ? palette.gray_10 : palette.brand_5,
    thirdText: isDark ? palette.disabledGray : palette.coal,
    forthText: isDark ? palette.disabledGray : palette.highlightIcon,
    fifthText: isDark ? palette.disabledGrayDark : palette.graphene,
    sixthText: isDark ? palette.white : palette.brand,
    tenthText: isDark ? palette.disabledGray : palette.graphene,
    brand20_brand: isDark ? palette.brand_20 : palette.brand,
    brand_trench: isDark ? palette.brand : palette.trench,
    brand_white: isDark ? palette.brand : palette.white,
    smoke_brand: isDark ? palette.smoke : palette.brand,
    disabledGray_muteMidGray: isDark
      ? palette.disabledGray
      : palette.muteMidGray,
    disabledGray_highlightIcon: isDark
      ? palette.disabledGrayDark
      : palette.highlightIcon,
    tooltipText: isDark ? palette.darkBackground : palette.smoke,
    leadText: isDark ? palette.disabledGray : palette.lead2,
    seventhText: isDark ? palette.muteMidGray : palette.highlightIcon,
    eighthText: isDark ? palette.muteMidGray : palette.trench,
    ninthText: isDark ? palette.disabledGray : palette.gray,
    primaryDisabledText: isDark ? palette.muteMidGray : palette.gray,
    primaryDisabledTextReverted: isDark ? palette.gray : palette.muteMidGray,
    gray_techGray20: isDark ? palette.gray : palette.techGray_20,
    secondaryDisabledText: isDark ? palette.disabledGrayDark : palette.gray,
    barProgress: isDark ? palette.barGradiantLight : palette.barGradiantLight,
    barBg: isDark ? palette.muteMidGray : palette.barBgLight,
    smoke: isDark ? palette.darkSmoke : palette.smoke,
    smoke2: isDark ? palette.muteMidGray : palette.smoke,
    smoke3: isDark ? palette.muteMidGray : palette.darkSmoke,
    darkSmoke_trench: isDark ? palette.darkSmoke : palette.trench,
    muteMidGray_disabledGray: isDark
      ? palette.muteMidGray
      : palette.disabledGray,
    modalHeaderBackground: isDark
      ? palette.popOverBg
      : palette.lightModalHeaderBackground,
    tableRowBgOdd: isDark ? palette.tableBgDarkOdd : palette.hover_50,
    tableRowBgEven: isDark ? palette.tableBgDarkEven : palette.snow,
    hoverPrimary: isDark ? palette.hover_75 : palette.hover_2,
    hoverPrimary2: isDark ? palette.hover_6 : palette.hover_2,
    hover75_hoverGray15: isDark ? palette.hover_75 : palette.hoverGray_15,
    hoverThird: isDark ? palette.muteMidGray : palette.hover_2,
    hoverSecondary: isDark ? palette.popOverBg_50 : palette.sky_30,
    skeletonBg: isDark ? palette.darkSecondary : palette.smoke,
    thumbnailHover: isDark ? palette.black_80 : palette.white_80,
    linkHoverColor: isDark ? palette.white_80 : palette.black_80,
    inputPlaceholder: isDark ? palette.disabledGrayDark : palette.gray_50,
    input: isDark ? palette.darkSmoke : palette.coal,
    tagHover: isDark ? palette.muteMidGray : palette.brand_20,
    white2040: isDark ? palette.white_20 : palette.white_40,
    darkHeaderBg_gray_5: isDark ? palette.darkHeaderBg : palette.gray_5,
    darkHeaderBg_snow: isDark ? palette.darkHeaderBg : palette.snow,
    snow_brand70: isDark ? palette.snow : palette.brand_70,
    topBarShadow: isDark ? palette.black_20 : palette.black_05,
    hover2_coal: isDark ? palette.hover_2 : palette.coal,
    darkHover_hover: isDark ? palette.darkHover : palette.hover,
    darkHover2_hover: isDark ? palette.darkHover_2 : palette.hover,
    hover_75_hover: isDark ? palette.hover_75 : palette.hover,
    black80_white80: isDark ? palette.black_80 : palette.white_80,
    black50_white50: isDark ? palette.black_50 : palette.white_50,
    headerBg_brand10: isDark ? palette.headerBg : palette.brand_10,
    darkSecondary_hover_50: isDark ? palette.darkSecondary : palette.hover_50,
    disabledGrayDark_coal: isDark ? palette.disabledGrayDark : palette.coal,
    hover_75_background2Light: isDark
      ? palette.hover_75
      : palette.background2Light,
    darkSecondary_autofillLight: isDark
      ? palette.darkSecondary
      : palette.autofillLight,
    trench_sky: isDark ? palette.trench : palette.sky,
    background2Light_White: isDark ? palette.background2Light : palette.white,
    background2Light_coal: isDark ? palette.background2Light : palette.coal,
    darkHeaderBg_white: isDark ? palette.darkHeaderBg : palette.white,
    darkSecondary_hoverGray15: isDark
      ? palette.darkSecondary
      : palette.hoverGray_15,
    smoke_gray: isDark ? palette.smoke : palette.gray,
    darkHeaderBg_hoverGray15: isDark
      ? palette.darkHeaderBg
      : palette.hoverGray_15,
    brand_brand20: isDark ? palette.brand : palette.brand_20,
    brand10_brand20: isDark ? palette.brand_10 : palette.brand_20,
    white_hover2: isDark ? palette.white : palette.hover_2,
    darkSecondary_brand10: isDark ? palette.darkSecondary : palette.brand_10,
    darkSecondary_brand20: isDark ? palette.darkSecondary : palette.brand_20,
    disabledGray_hover: isDark ? palette.disabledGray : palette.hover,
    trench_brand20: isDark ? palette.trench : palette.brand_20,
    hover2_graphene: isDark ? palette.hover_2 : palette.graphene,
    white_popOverBg: isDark ? palette.white : palette.popOverBg,
    smoke_hover75: isDark ? palette.hover_75 : palette.smoke,
    coal_disabledGray: isDark ? palette.coal : palette.disabledGray,
    graphene_hover: isDark ? palette.graphene : palette.hover,
    gray_graphene: isDark ? palette.gray : palette.graphene,
    hover75_techGray_20: isDark ? palette.hover_75 : palette.techGray_20,
    darkSecondary_gray10: isDark ? palette.darkSecondary : palette.gray_10,
    darkSecondary_gray: isDark ? palette.darkSecondary : palette.gray,
    darkSecondary_20_gray_10: isDark
      ? palette.darkSecondary_20
      : palette.gray_10,
    background2Light_60_popOverBg_60: isDark
      ? palette.popOverBg_60
      : palette.background2Light_60,
    darkSmoke_lead: isDark ? palette.darkSmoke : palette.lead,
    disabledGrayDark_disabledGray: isDark
      ? palette.disabledGrayDark
      : palette.disabledGray,
    darkSecondary10_white10: isDark
      ? palette.darkSecondary_10
      : palette.white_10,
    gray10_hover2: isDark ? palette.gray_10 : palette.hover_2,
    muteMidGray_gray80: isDark ? palette.muteMidGray : palette.gray_80,
    lightBrown: palette.lightBrown,
    muteMidGray_hover_6: isDark ? palette.muteMidGray : palette.hover_6,
    blueGradientLayover: palette.blueGradientLayover,
    blueGradientLayoverHover: palette.blueGradientLayoverHover,
    modalBlur: palette.modalBlur,
    popoverBg_hover: isDark ? palette.popOverBg : palette.hover,
    disabledGrayDark_gray: isDark ? palette.disabledGrayDark : palette.gray,
    disabledGray_graphene: isDark ? palette.disabledGray : palette.graphene,
  },
  breakpoints: {
    smallMobile: `@media (min-width: ${smallMobileBreakpoint}px)`,
    tablet: `@media (min-width: ${tabletBreakpoint}px)`,
    midDesktop: `@media (min-width: ${midDesktopBreakpoint}px)`,
    desktop: `@media (min-width: ${desktopBreakpoint}px)`,
    custom: (breakpoint: number | string): string =>
      `@media (min-width: ${breakpoint}px)`,
  },
  variables: {
    gutter: 16,
    desktopGutter: 32,
    largeGutter: 20,
    xLargeGutter: 24,
    sidePanelWidth: 72,
    leftPanelMaxWidth: 260, // the last num was 280 - for change ping Amer or Can
    chatPanelMaxWidth: 342,
    headerPopperMenuWidth: 324,
    avatarSize: 34,
    avatarSizeBig: 152,
    mainHeaderDesktopHeight: 72,
    headerMobileHeight: 56, // 32+12+12
    headerDesktopHeight: 64, // 32+16+16
    logoMinHeight: 24,
    logoMaxHeight: 32,
    contentMaxWidth: 980,
    wideContentMaxWidth: 1170,
    mainContentMaxWidth: 650,
    rightPaneMaxWidth: 270, // the last num was 250 - for change ping Amer or Can
    chatBubbleMaxWidth: 277,
    bottomBarHeight: 50,
    rightSideModalMaxWidth: 400,
    headerItemsGap: 39.5,
    safeAreaInsetBottom,
  },
  fonts: {
    thin: 'Roboto-Thin',
    regular: 'Roboto-Regular',
    medium: 'Roboto-Medium',
    bold: 'Roboto-Bold',
    light: 'Roboto-Light',
    black: 'Roboto-Black',
    italic: 'Roboto-Italic',
  },
  zIndex: {
    layout: 500,
    confirmation: 1000,
    popperMenu: 2000,
    popover: 2100,
    snackbar: 2500,
    tooltip: 3000,
    overlay: 3500,
  },
  transitions: {
    smooth: 'all 200ms ease-in-out',
    smoothProperty: (property: string) => `${property} 200ms ease-in-out`,
  },
  hoverEffects: {
    cardShadow: `0px 0px 30px ${isDark ? palette.white_04 : palette.black_05}`,
    cardBorder: `1px solid ${palette.gray_20}`,
  },
});

const colorsObj = makeTheme(false).colors;
const fontsObj = makeTheme(false).fonts;
export type colorsKeys = keyof typeof colorsObj;
export type FontsNamesType = keyof typeof fontsObj;
