import classes from './Link.component.module.scss';

import NextLink from 'next/link';
import React from 'react';
import cnj from '../utils/cnj';

export interface LinkProps {
  to: string;
  children: React.ReactNode;
  showUnderline?: boolean;
  className?: string;
  prefetch?: boolean;
  as?: string;
  onClick?: (e) => void;
}

const Link = ({
  children,
  showUnderline,
  className,
  prefetch = true,
  to,
  as,
  onClick,
}: LinkProps): JSX.Element =>
  to ? (
    <NextLink
      prefetch={prefetch}
      className={cnj(
        classes.linkHashRoot,
        showUnderline && classes.showUnderline,
        className
      )}
      href={to}
      as={as}
      onClick={onClick}
      // title={to}
    >
      {children}
    </NextLink>
  ) : (
    <div
      style={{ backgroundColor: 'red', padding: 6, border: '1px solid blue' }}
    >
      {children}
    </div>
  );

export default Link;
