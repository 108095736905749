import { makeTheme } from '../helpers/theme';
import { ThemeContext, ThemeProvider } from '../ThemeProvider';
const themeContext = ThemeContext;

export const themeObj = makeTheme(false, false);
const colorsObj = themeObj.colors;

export type colorsKeys = keyof typeof colorsObj;

export { ThemeProvider, themeContext };
