import React, { forwardRef } from 'react';
import IcomoonReact from 'icomoon-react';
import Flex from '../Flex';
import type { colorsKeys } from '../helpers/theme';
// @ts-ignore
import iconSet from './selection.json';
import useTheme from '../utils/useTheme';

export type IconFontType = 'fas' | 'far' | 'fal';

export interface IconProps {
  type?: IconFontType;
  name?: string;
  size?: number;
  color?: colorsKeys;
  className?: string;
  onClick?: any;
  style?: React.CSSProperties;
}

const Icon = (
  {
    type = 'fas',
    name,
    size = 20,
    color = 'primaryText',
    className,
    onClick,
    style,
  }: IconProps,
  ref: any
) => {
  const icon = type === 'fas' ? `${name}-s` : name;

  const themObj = useTheme();
  const iconColor = themObj?.theme?.colors?.[color] as string;

  return (
    <Flex as="span" className={className} onClick={onClick} ref={ref}>
      <IcomoonReact
        style={style}
        iconSet={iconSet}
        color={iconColor}
        size={size}
        icon={icon as string}
      />
    </Flex>
  );
};

export default forwardRef<any, IconProps>(Icon);
